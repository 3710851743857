import styled from "@emotion/styled"

import { typographyStyles } from "../typography/Typography"

export const ElementChildrenContainer = styled.div`
  ${typographyStyles.bodySmall};

  // The markdown component adds vertical margins to paragraphs
  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
