import styled from "@emotion/styled"
import { horizontal, vertical } from "../../variables/layout/Spacing"

type Props = {
  borderColor: string
  hasIcon: boolean
  hasTitle: boolean
}

export const AlertBorder = styled.div<Props>`
  border-left-color: ${props => props.borderColor};
  border-left-style: solid;
  border-left-width: 4px;
  box-sizing: border-box;
  padding-bottom: ${vertical.xs};
  padding-left: ${horizontal.s};
  padding-right: ${props => (props.hasIcon ? horizontal.xxl : horizontal.s)};
  padding-top: ${props => (props.hasTitle ? vertical.xxs : vertical.xs)};
`
