import React from "react"
import styled from "@emotion/styled"

import { colours } from "../../variables/colour/Colour"
import { ErrorIcon } from "../icons/Icons"
import { AlertBorder } from "./AlertBorder"

import { Container } from "./Container"
import { ElementChildrenContainer } from "./ElementChildrenContainer"
import { IconContainer } from "./IconContainer"
import { StringChildrenContainer } from "./StringChildrenContainer"
import { Title } from "./Title"

type AlertKind = "constructive" | "destructive" | "informative" | "warning"

type Props = {
  children: React.ReactNode
  kind: AlertKind
  title?: string
}

type ErrorIconComponentProps = {
  showIcon: boolean
  hasTitle: boolean
}

const colorForKind: Record<AlertKind, string> = {
  constructive: colours.constructive.main,
  destructive: colours.destructive.main,
  informative: colours.action.main,
  warning: colours.callout.dark,
}

const showIconForKind: Record<AlertKind, boolean> = {
  constructive: false,
  destructive: true,
  informative: false,
  warning: false,
}

const CenterBox = styled.div`
  display: flex;
  align-items: center;
`

const ErrorIconComponent: React.FC<ErrorIconComponentProps> = ({
  showIcon,
  hasTitle,
}) => {
  if (showIcon) {
    return (
      <IconContainer hasTitle={hasTitle}>
        <ErrorIcon color={colours.offBlack} />
      </IconContainer>
    )
  }

  return null
}

const AlertChildren: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  if (typeof children == "string") {
    return <StringChildrenContainer>{children}</StringChildrenContainer>
  }

  return <ElementChildrenContainer>{children}</ElementChildrenContainer>
}

export const Alert: React.FC<Props> = ({ children, kind, title }) => {
  const color = colorForKind[kind]
  const showIcon = showIconForKind[kind]

  return (
    <Container>
      <AlertBorder borderColor={color} hasIcon={showIcon} hasTitle={!!title}>
        {title ? (
          <React.Fragment>
            <CenterBox>
              <Title>{title}</Title>
              <ErrorIconComponent showIcon={showIcon} hasTitle={true} />
            </CenterBox>
            <AlertChildren children={children} />
          </React.Fragment>
        ) : (
          <CenterBox>
            <ErrorIconComponent showIcon={showIcon} hasTitle={false} />
            <AlertChildren children={children} />
          </CenterBox>
        )}
      </AlertBorder>
    </Container>
  )
}
