import styled from "@emotion/styled"
import { borderRadii } from "../../variables/border/Radii"
import { colours } from "../../variables/colour/Colour"

export const Container = styled.div`
  border: 2px solid ${colours.offBlack};
  border-radius: ${borderRadii.xs};
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  background-color: ${colours.white};
`
